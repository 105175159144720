var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-template-table fill-height"},[_c('div',{staticClass:"d-flex justify-end"},[_c('router-link',{attrs:{"to":{ name: 'MessageTemplateNew', params: { type: _vm.messageType } }}},[_c('v-btn',{staticClass:"my-1",attrs:{"color":"primary"}},[_vm._v(" New ")])],1)],1),_c('v-data-table',{staticClass:"\n      message-template-items-table__data-table\n      fill-height\n      d-flex\n      flex-column\n      justify-space-between\n    ",class:{ 'pointer-events-none': _vm.paginationChanging },attrs:{"headers":_vm.headers,"items":_vm.templates.list,"loading":_vm.isLoading,"page":_vm.templates.pagination.page,"items-per-page":_vm.templates.pagination.per_page,"server-items-length":_vm.templates.pagination.total,"loading-text":("Loading " + _vm.messageType + " templates"),"fixed-header":"","disabled":_vm.isLoading},on:{"update:page":[function($event){return _vm.$set(_vm.templates.pagination, "page", $event)},_vm.onPageChange],"update:serverItemsLength":function($event){return _vm.$set(_vm.templates.pagination, "total", $event)},"update:server-items-length":function($event){return _vm.$set(_vm.templates.pagination, "total", $event)},"update:items-per-page":_vm.onItemsPerPageChange},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeStringFromIso(item.updatedAt))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeStringFromIso(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.editMessageTemplate(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit "+_vm._s(_vm.messageType)+" template")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.openDeletePrompt(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete "+_vm._s(_vm.messageType)+" Template")])])]}}],null,true)}),(_vm.deletePopup && _vm.deleteId)?_c('delete-template',{attrs:{"delete-id":_vm.deleteId},on:{"delete-template":_vm.deleteMessageTemplate},model:{value:(_vm.deletePopup),callback:function ($$v) {_vm.deletePopup=$$v},expression:"deletePopup"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }