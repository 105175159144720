




































import { defineComponent, computed, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'DeleteTemplate',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    deleteId: {
      type: String,
      required: true,
    },
  },
  emits: ['delete-template'],
  setup(props, { emit }) {
    const isLoading = ref(false);

    const open = computed({
      get: () => props.value,
      set: (val) => emit('input', val),
    });

    /**
     *  @summary Confirms the deletion and emits an event with the ID to the parent component.
     *  @author Jack O'Connor
     */
    const confirmDelete = () => {
      isLoading.value = true; // covers the delay between caller function and closure of the popup
      emit('delete-template', props.deleteId);
    };

    return {
      isLoading,
      open,
      confirmDelete,
    };
  },
});
