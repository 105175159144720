























import { defineComponent, ref, PropType } from '@vue/composition-api';
import MessageTemplatesTable from '@/components/MessageTemplates/MessageTemplatesTable.vue';

export default defineComponent({
  name: 'MessageTemplates',
  components: {
    MessageTemplatesTable,
  },
  props: {
    tab: {
      type: String as PropType<'mail' | 'sms'>,
      required: false,
      default: 'mail',
    },
  },
  setup(props) {
    const currTab = ref();

    if (props.tab === 'mail') {
      currTab.value = 0;
    } else {
      currTab.value = 1;
    }

    return {
      currTab,
    };
  },
});
