
























































































import {
  defineComponent,
  computed,
  ref,
  PropType,
} from '@vue/composition-api';
import { getDateTimeStringFromIso, formatDateString } from '@/utils';
import router from '@/router';
import store from '@/store';
import DeleteTemplate from './popup/DeleteTemplate.vue';

export default defineComponent({
  name: 'MessageTemplatesTable',
  components: {
    DeleteTemplate,
  },
  props: {
    messageType: {
      type: String as PropType<'mail' | 'sms'>,
      required: true,
    },
  },
  setup(props) {
    const apiRefresh = ref(false);
    const isLoading = ref(false);
    const deletePopup = ref(false);
    const deleteId = ref();
    const paginationChanging = ref(false);

    const headers = ref([
      { value: 'name', text: 'Name', sortable: false },
      { value: 'recipient', text: 'Recipient', sortable: false },
      { value: 'createdAt', text: 'Created', sortable: false },
      { value: 'updatedAt', text: 'Last Edited', sortable: false },
      { value: 'actions', text: '', sortable: false },
    ]);

    const templates = computed(() => {
      const mail = store.getters['new/messageTemplate/getMessageTemplates'](props.messageType);
      return mail || { list: [] };
    });

    /**
     *  @summary Redirects the user to the edit view
     *  @author Jack O'Connor
     */
    const editMessageTemplate = (id: string) => {
      router.push({
        name: 'MessageTemplateEdit',
        params: { id, type: props.messageType },
      });
    };

    /**
     *  @summary Opens a prompt to confirm deletion to avoid accidents.
     *  @author Jack O'Connor
     */
    const openDeletePrompt = async (id: string) => {
      deleteId.value = id;
      deletePopup.value = true;
    };

    /**
     *  @summary Deletes a message template
     *  @author Jack O'Connor
     */
    const deleteMessageTemplate = async (id: string) => {
      isLoading.value = true;
      await store.dispatch('new/messageTemplate/deleteMessageTemplate', { type: props.messageType, id });
      isLoading.value = false;
      deleteId.value = undefined;
    };

    /**
     *  @summary Loads all message templates of a specific type
     *  @author Jack O'Connor
     */
    const loadMessageTemplates = async (params = {}) => {
      await store.dispatch('new/messageTemplate/readAllMessageTemplates', { type: props.messageType, params });
    };

    /**
     *  @summary Re-loads message templates based on the new parsed page
     *  @author Jack O'Connor
     */
    const onPageChange = async (page: number) => {
      paginationChanging.value = true;
      await loadMessageTemplates({
        per_page: templates.value.pagination.per_page,
        page,
      });
      paginationChanging.value = false;
    };

    /**
     *  @summary Re-loads message templates based on the new per_page value
     *  @author Jack O'Connor
     */
    const onItemsPerPageChange = async (per_page: number) => {
      paginationChanging.value = true;
      await loadMessageTemplates({
        page: 1,
        per_page,
      });
      paginationChanging.value = false;
    };

    if (templates.value && templates.value.lastCalled) {
      // Checks if the API was called more than 5 mins ago (stale)
      apiRefresh.value = new Date(templates.value.lastCalled.getTime() + (60000 * 5)) < new Date();
    }

    if (!templates.value.list || (templates.value.list && templates.value.list.length === 0) || apiRefresh.value) {
      isLoading.value = true;
      loadMessageTemplates()
        .finally(() => {
          isLoading.value = false;
        });
    }

    return {
      templates,
      headers,
      isLoading,
      deletePopup,
      deleteId,
      getDateTimeStringFromIso,
      formatDateString,
      deleteMessageTemplate,
      editMessageTemplate,
      onPageChange,
      onItemsPerPageChange,
      openDeletePrompt,
      paginationChanging,
    };
  },
});
